.info-bug-item {
  position: relative;
  width: 16px;
  min-height: 16px;
}

.info-bug {
  cursor: default;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var( --color-gray-lighter );
  color: var(--color-white);
  font-style: italic;
  font-family: $font-stack-serif;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
}
