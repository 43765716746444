.item-tags-add {
  position: relative;
  transition: margin-top 0.4s ease,
              opacity 0.4s ease;

  &.active {
    opacity: 1;
  }

  &.inactive {
    opacity: 0;
  }

  .item-tags-content + & {
    &.active {
      margin-top: 9px;
    }
  }

  .tags-item-title {
    display: flex;
    align-items: center;
  }

  .tags-item-title-field {
    padding: 6px 9px;
    width: 100%;
    border-radius: 14px;
    border: 1px solid var( --color-gray-lightest );
    font-size: 11px;
    line-height: 14px;

    &::placeholder {
      color: var( --color-gray-medium );
    }
  }

  .tags-item-title-field + .item-actions {
    margin-left: 18px;
  }

  .tags-item-list {
    position: absolute;
    top: calc(100% + 9px);
    left: -9px;
    right: -9px;
    padding: 9px;
    box-shadow: 0 2px 6px rgba( var( --color-gray-medium-rgb ), 0.5 );
    border-radius: 9px;
    background-color: var(--color-white);
    z-index: 1;
  }
}

.tags-item-title {
  overflow: hidden;
  transition: height 0.4s ease;

  .item-tags-add.active & {
    min-height: 27px;
  }

  .item-tags-add.inactive & {
    height: 0;
  }
}
