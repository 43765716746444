.item-tags {
  transition: height 0.4s ease;

  &.inactive {
    height: 0;
    overflow: hidden;
  }
}

.item-tags-content {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .item-actions {
    height: 30px;

    .item-action {
      &.item-action-edit {
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}
