.item-large {
  .item-readable-key {
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
  }

  a.item-readable-key {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  .item-header {
    align-items: flex-start;
  }

  .item-header + .item-description {
    margin-top: 9px;
  }

  .item-description-content {
    font-weight: 200;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.25px;
  }

  .item-meta {
    margin-top: 6px;
    width: 100%;
  }
}
