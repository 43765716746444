.buttons-new {

  &.buttons-small {
    width: $button-small-width;
  }

  &.buttons-medium {
    width: $button-medium-width;
  }

  &.buttons-large {
    width: $button-medium-width;
  }

  &.buttons-stack {
    position: relative;
    top: 0;
    width: 100%;
    transition: top 0.4s ease;
    z-index: 4;

    &.buttons-small {
      height: $button-small-height;
    }

    &.buttons-medium {
      height: $button-medium-height;
    }
  }

  &.buttons-set {
    width: 100%;

    &.buttons-small {
      height: $button-small-height;
    }

    &.buttons-medium {
      height: $button-medium-height;
    }

    .button-new {
      float: right;
      height: 100%;
    }
  }

  &.buttons-pinned {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
  }

  @include at_print {
    display: none;
  }
}
