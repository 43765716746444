.item-triggerable {
  display: flex;
  align-items: center;
  padding: 6px 0;
  min-height: 6px + 21px;
  transition: padding-top 0.25s ease,
              padding-bottom 0.25s ease,
              height 0.25s ease,
              min-height 0.25s ease,
              opacity 0.25s ease,
              top 0.25s ease;

  &::before {
    content: '';
    display: block;
    flex-shrink: 0;
    margin-right: 24px;
    width: 12px;
    height: 12px;
    background-color: transparent;
    background-image: image( 'icons/ui/plus-thin-gray.svg' );
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .item-triggerable-triggers {
    flex-shrink: 0;
    flex-grow: 1;
  }

  &.active {
    opacity: 1;

    .item-triggerable-action {
      cursor: pointer;

      & > .item-action {
        & > .item-action-el {
          opacity: 1;
        }
      }
    }
  }

  &.inactive {
    opacity: 0.2;

    .item-triggerable-action {
      cursor: default;
    }
  }

  .item-triggerable-actions {
    color: var( --color-gray-medium );
    font-size: 12px;
    line-height: 24px;
  }

  .item-triggerable-action {
    position: relative;
    display: inline;
    overflow: hidden;
    transition: all 0.25s ease;

    .item-action {
      &.item-action-menu {
        display: inline;

        .item-action-el {
          display: inline;
        }
      }
    }
  }

  .item-triggerable-object {
    display: inline-block;
    font-weight: 600;
    letter-spacing: 0.25px;
    transition: color 0.4s ease;

    &:hover {
      color: var( --color-gray-darker );
    }
  }

  @include at_print {
    display: none;
  }
}
