.item-field {
  position: relative;
  border-bottom: 1px solid var( --color-gray-lightest );

  .item-field-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    color: rgb( var( --color-blue-dusty-lighter-rgb ) );
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.5px;

    & + input[type="text"],
    & + input[type="password"] {
      margin-top: 3px;
    }

    .info-bug-item {
      margin-left: 9px;
      width: 12px;
      min-height: 12px;

      .info-bug {
        cursor: pointer;
        width: 12px;
        height: 12px;
        background-color: rgb( var( --color-blue-dusty-lighter-rgb ) );
        font-size: 9px;
        line-height: 12px;
      }

      .tooltip {
        left: -90px + 6px;
      }
    }
  }

  label {
    transition: all 0.4s ease;
  }

  .item-field-content,
  input[type="text"],
  input[type="password"] {
    margin-bottom: -2px;
    padding-top: 6px;
    padding-bottom: 9px;
    font-size: 14px;
    line-height: 1;
  }

  input[type="text"],
  input[type="password"] {
    display: block;
    width: 100%;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    color: var( --color-gray-darker );
    transition: all 0.4s ease;

    &:hover {
      border-bottom-color: var( --color-gray-medium );
    }

    &:focus {
      border-bottom-color: var( --color-gray-darker );
    }
  }

  input[type="checkbox"] {
    & + .item-field-label-boolean {
      margin-left: 0.25em;
      font-size: 14px;
    }
  }

  & {
    & + .item-field,
    & + .item-field-group {
      margin-top: 9px;
    }
  }

  &.item-field-inline {
    & + .item-field,
    & + .item-field-group {
      margin-top: 15px;
    }
  }

  @media screen and (max-width: $width-standard) {
    &.item-field-full,
    &.item-field-half,
    &.item-field-eighth,
    &.item-field-quarter,
    &.item-field-three-quarters  { width: 100%; }
  }

  @media screen and (min-width: $width-standard) {
    &.item-field-full            { width: 100%; }
    &.item-field-half            { width: 49%; }
    &.item-field-eighth          { width: 10.75%; }
    &.item-field-quarter         { width: 23.5%; }
    &.item-field-three-quarters  { width: 74.5%; }
  }
}

.item-field-helper {
  margin: 9px 0 18px;
  color: var( --color-gray-light );
}

.item-field-major {
  input[type="text"],
  input[type="password"] {
    font-size: 21px;
  }
}

.item-field-last {
  border-bottom: none;
}

.item-field-inline {
  @media screen and (max-width: $width-standard) {
    display: block;

    .item-field-label {
      width: 100%;

      & + .item-field-content,
      & + input[type="text"],
      & + input[type="password"] {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: $width-standard) {
    display: flex;

    .item-field-label {
      flex-shrink: 0;
      padding-top: 9px;
      padding-bottom: 9px;
      width: 23.5%;

      & + .item-field-content,
      & + input[type="text"],
      & + input[type="password"] {
        margin-top: 0;
      }

      & + .item-field-content,
      & + input,
      & + select {
        margin-left: 2%;
        width: 74.5%;
      }
    }
  }
}

.item-field-group {
  @media screen and (max-width: $width-standard) {
    display: block;

    .item-field + .item-field {
      margin-top: 9px;
    }
  }

  @media screen and (min-width: $width-standard) {
    display: flex;

    .item-field + .item-field {
      margin-top: 0;
      margin-left: 2%;
    }
  }

  .item-field + &,
  .item-field-group + & {
    margin-top: 9px;
  }

  & + .item-field,
  & + .item-field-group {
    margin-top: 9px;
  }

  &.item-field-group-meta {
    padding: 9px;
    border-radius: 3px;
    background-color: var( --color-gray-lightest );

    .item-field {
      border-bottom-color: var( --color-gray-light );

      ::placeholder {
        color: var( --color-gray-light );
      }

      .item-field-label {
        color: var( --color-blue-dusty-medium );
      }
    }

    .item-field + &,
    .item-field-group + & {
      margin-top: 27px;
    }
  }
}

.item-field-select {
  select {
    display: block;
    margin-top: 8px;
    margin-bottom: -2px;
    padding-bottom: 8px;
    width: 100%;
    border-radius: 0;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    color: var( --color-gray-darker );
    font-size: 14px;
    line-height: 18px;
    -webkit-appearance: none;

    &:hover {
      border-bottom-color: var( --color-gray-medium );
    }

    &:focus {
      border-bottom-color: var( --color-gray-darker );
    }
  }
}

.item-field-value {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 9px;

  .item-field-inline & {
    padding-top: 6px;
    padding-bottom: 9px;
    width: calc(100% - 100px);
  }
}

.item-field-boolean {
  align-items: center;
  height: auto;

  input[type="checkbox"] {
    & + label[for] {
      cursor: pointer;
      display: block;
      margin-left: -16px;
      margin-bottom: -11px;
      padding-left: 27px;
      padding-bottom: 10px;
      width: 100%;
      border-bottom: 2px solid transparent;
      color: var( --color-gray-darker );
      font-size: 14px;

      &:hover {
        border-bottom-color: var( --color-gray-medium );
      }
    }

    &:focus {
      & + label[for] {
        border-bottom-color: var( --color-gray-darker );
      }
    }
  }
}

.item-field-table {
  width: 100%;

  th {
    padding: 9px;
    color: var( --color-gray-light );
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &.item-field-table-cell-label {
      text-align: left;
    }

    &.item-field-table-cell-number {
      text-align: right;
    }

    &.item-field-table-cell-action {
      text-align: right;
    }
  }

  td {
    padding: 9px;
    border-top: 1px solid var( --color-gray-lightest );
    vertical-align: middle;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &.item-field-table-cell-label {
      color: rgb( var( --color-blue-dusty-lighter-rgb ) );
      font-size: 10px;
      letter-spacing: 0.5px;
      text-align: left;
    }

    &.item-field-table-cell-number {
      font-size: 12px;
      text-align: right;
    }

    &.item-field-table-cell-action {
      width: calc(24px + 9px);
      text-align: right;

      .item-actions {
        justify-content: flex-end;
      }
    }
  }
}

.item-field-submit {
  @include clearfix;
  border-bottom: none;

  .button-new {
    float: right;
  }
}

.item-fields-meta {
  padding: 9px;
  border-radius: 3px;
  background-color: var( --color-gray-lightest );

  .item-field {
    border-bottom-color: var( --color-gray-light );

    ::placeholder {
      color: var( --color-gray-light );
    }

    .item-field-label {
      color: var( --color-blue-dusty-medium );
    }
  }

  @media screen and (min-width: $width-standard) {
    .item-field + .item-field {
      margin-top: 0;
    }
  }
}

.item-field-small {
  select {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  }
}
