.flash {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0;
  z-index: 15;

  @media screen and (min-width: $width-mobile) {
    width: 2 * $layout-width-small;
  }

  @media screen and (max-width: $width-mobile) {
    width: 100%;
  }
}

.flash-messages {
  margin: 9px;
}

.flash-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba( var( --color-gray-medium-rgb ), 0.5 );
  background-color: var( --color-white );
  color: var( --color-black );
  transition: margin 250ms ease,
              min-height 250ms ease,
              opacity 250ms ease,
              transform 250ms ease;

  &.active {
    margin: 0;
    min-height: 36px;
    opacity: 1;
    transform: translateY(0);
  }

  &.inactive {
    margin: -9px 0;
    min-height: 0;
    height: 0;
    opacity: 0;
    transform: translateY(18px);
  }

  a {
    color: inherit;
    text-decoration: underline;
    transition: color 0.4s ease;
  }
}

.flash-message.active ~ .flash-message.active {
  margin-top: 3px;
}

.flash-message-content {
  font-size: 13px;
  line-height: 18px;

  .action-object {
    font-weight: bold;
  }
}

.flash-message-dismiss {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: rgba( var( --color-black-rgb ), 0.2 );
  overflow: hidden;
  cursor: pointer;

  &:before {
    content: '\2013';
    display: block;
    width: 18px;
    height: 18px;
    color: var( --color-white );
    font-size: 15px;
    line-height: 18px;
    text-align: center;
  }
  
  &:hover {
    background-color: rgba( var( --color-black-rgb ), 0.5 );
  }
}

.flash-message-content + .flash-message-dismiss {
  margin-left: 18px;
}

.flash-message-successes {
  background-color: var( --color-green-medium );
  color: var( --color-white );

  .flash-message-content {
    font-weight: 200;
  }
}

.flash-message-minor-successes {
  color: var( --color-green-medium );
}

.flash-message-warnings {
  color: var( --color-red-medium );
}
