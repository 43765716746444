.item-kind-and-date {
  .item-kind-of {
    .value {
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .item-reference {
    &::before {
      content: '\2022';
      margin-left: 4px;
      margin-right: 2px;
      color: var( --color-gray-lighter );
    }
  }

  .item-date {
    .value {
      time {
        font-weight: 600;
      }
    }
  }

  .item-data + .item-date {
    border-left: 1px solid var( --color-gray-lighter );
    margin-left: 9px;
    padding-left: 9px;
  }
}
