.item-field-has-suggestions {
  position: relative;
}

.item-field-suggestions {
  position: absolute;
  top: calc(100% - 3px);
  left: -12px;
  padding: 6px 0;
  min-width: 100%;
  box-shadow: 0px 3px 4px rgba( var( --color-gray-lighter-rgb ), 0.5);
  background-color: var( --color-white );
  z-index: 1;

  @media screen and (max-width: $width-mobile) {
    max-width: 100%;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 12px;
      background-image: linear-gradient( to right,
        rgba( var( --color-white-rgb ), 0 ) 0%,
        rgba( var( --color-white-rgb ), 1 ) 100%);
    }
  }
}

.item-field-suggestion {
  padding: 6px 12px;
  cursor: pointer;

  &:hover,
  &.selected {
    background-color: rgba( var( --color-blue-dusty-lighter-rgb ), 0.2);
  }
}
