@function image($filename) {
  @return url( $image-directory + $filename );
}

@mixin clearfix {
  &::before,
  &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin at_print {

  // .printing & {
  //   @content;
  // }

  @media print {
    @content;
  }
}
