//  clearfix

%clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

//  hovers

%hovers {
  -webkit-user-select: none;
  -webkit-touch-callout: none;        
}

//  text-replace

%text-replace {
  text-indent: 160%;
  white-space: nowrap;
  overflow: hidden;
}
