.item-field-new {
  position: relative;

  label {
    transition: all 0.4s ease;
  }

  .item-field-new-content,
  input[type="text"],
  input[type="password"] {
    margin-bottom: -2px;
    padding-top: 6px;
    padding-bottom: 9px;
    font-size: 14px;
    line-height: 1;
  }

  input[type="text"],
  input[type="password"] {
    display: block;
    width: 100%;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    color: var( --color-gray-darker );
    transition: all 0.4s ease;

    &:hover {
      border-bottom-color: var( --color-gray-medium );
    }

    &:focus {
      border-bottom-color: var( --color-gray-darker );
    }
  }

  input[type="checkbox"] {
    font-size: 14px;
    line-height: 1;

    & + .item-field-new-label-boolean {
      display: block;
      margin-left: 8px;
      line-height: 1;
    }
  }
}

.item-field-new-label {
  display: block;
  padding: 0;
  color: rgb( var( --color-blue-dusty-lighter-rgb ) );
  font-size: 10px;
  letter-spacing: 0.5px;

  & + .item-field-new,
  & + .item-field-new-group {
    margin-top: 9px;
  }
}

.item-field-new-major {
  input[type="text"] {
    font-size: 21px;
  }
}

.item-field-new-inline {
  @include clearfix;

  .item-field-new-label {
    float: left;
    padding: 9px 0;
    width: 20%;

    & + .item-field-new-content,
    & + input[type="text"],
    & + input[type="password"] {
      float: left;
      margin-top: 0;
      width: 80%;
    }

    & + input[type="checkbox"] {
      margin-left: -1.25em;
    }
  }
}

.item-field-new-group {
  display: flex;

  .item-field-new {
    // float: left;
  }

  @media screen and (max-width: $width-standard) {

    .item-field-new + .item-field-new {
      margin-top: 9px;
    }

    .item-field-new-half,
    .item-field-new-eighth,
    .item-field-new-quarter,
    .item-field-new-three-quarters  { width: 100%; }
  }

  @media screen and (min-width: $width-standard) {

    .item-field-new + .item-field-new {
      margin-top: 0;
      margin-left: 2%;
    }

    .item-field-new-half            { width: 49%; }
    .item-field-new-eighth          { width: 10.75%; }
    .item-field-new-quarter         { width: 23.5%; }
    .item-field-new-three-quarters  { width: 74.5%; }
  }
}

.item-field-new + .item-field-new,
.item-field-new + .item-field-new-group,
.item-field-new-group + .item-field-new,
.item-field-new-group + .item-field-new-group {
  margin-top: 9px;
}

.item-field-new-select {
  select {
    display: block;
    margin-top: 8px;
    margin-bottom: -2px;
    padding-bottom: 8px;
    width: 100%;
    border-radius: 0;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    color: var( --color-gray-darker );
    font-size: 14px;
    line-height: 18px;
    -webkit-appearance: none;

    &:hover {
      border-bottom-color: var( --color-gray-medium );
    }

    &:focus {
      border-bottom-color: var( --color-gray-darker );
    }
  }
}

.item-field-new-boolean input[type='checkbox'],
.item-field-new-label-boolean {
  cursor: pointer;
  color: var( --color-gray-medium );
}

.item-field-new-boolean {
  display: flex;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;

  @media screen and (max-width: $width-standard) {
    height: auto;
  }

  @media screen and (min-width: $width-standard) {
    height: 45px;
  }

  .item-field-new-label {
    & + input[type="checkbox"] {
      margin-top: 12px;
    }
  }

  input[type="checkbox"] {
    & + label[for] {
      // margin-left: 8px;
    }
  }
}

.item-field-new-label-boolean {
  font-size: 12px;
  font-weight: 200;
  letter-spacing: 0.5px;
}

.item-field-new-submit {
  @include clearfix;
  border-bottom: none;

  .button-new {
    float: right;
  }
}

@media screen and (max-width: $width-standard) {
  .item-field-new-inline {
    .item-field-new-label {
      float: none;
      width: 100%;

      & + input[type="text"], 
      & + input[type="password"] {
        float: none;
        width: 100%;
      }
    }
  }
}
