@import "lib/all";
@import "var/all";
@import "base";
@import 'headings';
@import 'header-global-nav-title';
@import 'flash';
@import 'items/all';
@import 'fields/all';

#at_login {
  .global-header + .main {
    margin-top: 54px;
  }

  .global-header {
    display: flex;
    justify-content: center;

    .global-header-content {
      padding: 0 18px;
      max-width: 450px;
      width: 100%;
    }
  }

  .section-layout {
    display: flex;
    justify-content: center;

    .section-primary {
      padding: 0 18px;
      max-width: 450px;
      width: 100%;
    }
  }

  .item-details {
    .item-header-and-meta {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .item-header {
      h3 {
        display: inline-block;
        margin: 0;
        border-bottom: 1px solid var( --color-gray-light );
        color: var( --color-gray-medium );
        font-weight: 600;
        letter-spacing: 0.5px;
      }
    }

    .item-header + .item-description,
    .item-header-and-meta + .item-description,
    .item-header-and-meta + .item-parent-description {
      margin-top: 27px;
    }
  }

  .item-description {
    p {
      color: var( --color-gray-light );
      font-size: 12px;
      line-height: 18px;
    }
  }

  @media screen and (max-width: $width-standard) {
    .global-header {
      padding-left: 0;
    }
  }
}
