.record-delete {
  @include clearfix;

  .record-message {
    padding: 18px;
    background-color: rgba( var( --color-white-rgb ), 0.8 );

    .item-readable-key {
      font-weight: 600;
    }

    p.major {
      font-size: 18px;
      line-height: 24px;
    }

    p.minor {
      font-size: 12px;
      line-height: 18px;
    }

    p.major + p.minor {
      margin-top: 3px;
    }
  }

  .record-controls {
    margin-left: 18px;
    margin-right: 18px;
  }

  .record-message + .record-controls {
    margin-top: 18px;
  }
}
