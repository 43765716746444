.item-reference {
  white-space: nowrap;

  .label,
  .value {
    letter-spacing: 1px;
  }

  .value {
    font-weight: 600;
  }

  .label + .value {
    margin-left: 0;
  }

  input[type="text"].value {
    width: 54px;
    text-align: right;
  }
}
