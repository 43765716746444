* {
  -webkit-tap-highlight-color: transparent;
}

html {
  height: 100%;
  font-size: $root-font-size + px;
}

body {
  height: 100%;
  background-color: var( --color-background );
  color: var( --color-default );
  font-family: $font-stack;
  font-weight: normal;
  font-size: 100%;
}

a {
  color: var( --color-link );
  text-decoration: none;
  transition: color 0.4s ease;

  &:active {
    text-decoration: underline;
  }

  &:hover {
    color: var( --color-link-hover );
    text-decoration: none;
  }
}

strong, b {
  font-weight: bold;
}

em, i {
  font-style: italic;
}

p {
  font-size: 12px;

  &.nada {
    font-size: 14px;
  }

  &.medium {
    font-size: 16px;
    line-height: 21px;

    & + .medium {
      margin-top: 1em;
    }
  }
}

::-webkit-input-placeholder {
  color: var( --color-gray-lightest );
}

::-moz-placeholder {
  color: var( --color-gray-lightest );
}

:-ms-input-placeholder {
  color: var( --color-gray-lightest );
}
