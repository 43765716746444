.item-list {
  .heading {
    & + .item-list-items,
    & + .item-list-description {
      margin-top: 18px;
    }
  }

  .item-list-item.active ~ .item-list-item.active {
    border-top: 1px solid var( --color-gray-lightest );
  }

  .pagination {
    margin-left: 12px;
  }
}

.item-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;

  .item-readable-key {
    font-size: 13px;
    line-height: 18px;

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  a.item-readable-key {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  .item-details + .item-meta {
    margin-left: 9px;
  }
}
