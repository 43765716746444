.item-edit {
  position: relative;
  transition: top 0.4s ease,
              opacity 0.4s ease;

  &.active {
    top: 0px;
    opacity: 1;
    overflow: hidden;
  }

  &.inactive {
    top: -18px;
    opacity: 0;
  }

  .item-details {
    .item-header-and-meta {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .item-header {
      h3 {
        display: inline-block;
        border-bottom: 1px solid var( --color-gray-light );
        color: var( --color-gray-medium );
        font-weight: 600;
        letter-spacing: 0.5px;
      }
    }

    .item-header ~ .item-description,
    .item-header-and-meta ~ .item-description {
      margin-top: 27px;
    }
  }

  .item-description {
    position: relative;
  }

  .item-description + .item-description {
    margin-top: 27px;
  }

  .item-parent-description + .item-description {
    margin-top: 27px;
  }

  &.active ~ &.active {
    margin-top: 18px;
  }
}
