.item-status {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .item-status-indicator {
    position: relative;
    transform: translateY(-8px);
    width: 100%;
    height: 4px;
    background: rgba( var(--color-black-rgb), 0 );
    overflow: hidden;
    transition: transform 0.25s ease,
                background 0.25s ease,
                box-shadow 0.25s ease;

    &.has-status {
      transform: translateY(-4px);

      &.success {
        background: rgba( var( --color-green-medium-rgb ), 0.8 );
        box-shadow: 0 2px 4px rgba( var( --color-green-medium-rgb ), 0.8 );
      }

      &.failure {
        background: rgba( var( --color-red-medium-rgb ), 0.8 );
        box-shadow: 0 2px 4px rgba( var( --color-red-medium-rgb ), 0.8 );
      }
    }

    &.success {
      box-shadow: 0 2px 4px rgba( var( --color-green-medium-rgb ), 0 );
    }

    &.failure {
      box-shadow: 0 2px 4px rgba( var( --color-red-medium-rgb ), 0 );
    }
  }

  &::after {
    position: relative;
    display: block;
    content: '';
    height: calc(100% - 4px);
    z-index: -1;
  }
}
