.item-choices-ui {
  .item-choice {
    cursor: pointer;
    display: flex;
    padding: 9px 12px;
    background-color: rgba( var( --color-white-rgb), 0.8 );
    transition: background-color 0.4s ease;

    &::before {
      content: ' ';
      display: block;
      flex-basis: 12px;
      flex-shrink: 0;
      margin-right: 12px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid var( --color-gray-darker );
      background-color: rgba( var( --color-white-rgb ), 0 );
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 100%;
      transition: background-color 0.4s ease;
    }

    &:hover {
      background-color: var( --color-white );

      &::before {
        background-color: var( --color-gray-darker );
      }
    }
  }

  .item-choice-label {
    color: var( --color-black );
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
    font-weight: 400;
    text-transform: none;
  }
}
