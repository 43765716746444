q {
  quotes: revert;

  &::before {
    content: open-quote;
  }
  &::after {
    content: close-quote;
  }
}
