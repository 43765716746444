.item-expand {
  cursor: pointer;
  margin-left: -3px;
  padding: 4.5px;
  width: 18px;
  height: 18px;
  background: transparent image('icons/ui/triangle-right.svg') center center no-repeat;
  transform: rotate(0deg);
  transform-origin: center;
  transition: transform 0.25s ease;
  filter: brightness(4);

  &.active {
    transform: rotate(90deg);
  }

  &.inactive {
    transform: rotate(0deg);
  }
}
