ul.autocomplete-list {
  position: absolute;
  margin: 28px 0 0 -9px;
  width: 240px;
  max-height: 26px * 5;
  overflow: auto;
  background-color: rgba( var( --color-white-rgb ), 0.85 );
  z-index: 2;

  li {
    padding: 6px;
    cursor: pointer;

    &.active {
      display: block;
    }

    &.inactive {
      display: none;
    }

    &:hover {
      background-color: rgba( var( --color-link-rgb ), 0.2 );
    }

    &.selected {
      background-color: var( --color-link );
      color: var(--color-white);
    }
  }
}
