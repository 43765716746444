.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 0;
  color: var( --color-gray-medium );

  h2 {
    font-weight: 200;
  }

  a,
  .heading-description {
    color: var( --color-gray-medium );
    font-weight: 400;
    transition: color 0.4s ease;
  }

  .heading-description {
    display: inline-flex;
    align-items: center;

    a + .item-actions {
      margin-left: 3px;
    }

    .item-actions {
      .item-action {
        width: 24px;
        height: 24px;

        &.item-action-menu {
          .item-action-el {
            &.item-action-ellipses {
              &::after {
                transform: rotate(0);
                background-image: image( 'icons/octicons/triangle-down-gray-medium.svg' );
                background-size: 35%;
              }
            }
          }

          &.item-active {
            .item-action-el {
              &.item-action-ellipses {
                &::after {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }
  }

  a {
    &:hover {
      color: var(--color-black);
    }
  }

  .heading-details {
    font-weight: 200;
  }

  .heading-divider {
    color: var( --color-gray-lighter );
  }

  & + form,
  & + .item-detail,
  & + .sources {
    margin-top: 9px;
  }

  @include at_print {
    display: none;
  }
}

.heading-inline {
  display: inline-block;
}

.heading-explainer {
  padding-top: 3px;
  padding-bottom: 6px;
  min-height: 36px;

  .heading-explainer-label {
    border-bottom: 1px solid var( --color-gray-lighter );

    .item {
      font-weight: 600;
    }

    & + .add-item-triggers {
      margin-left: 9px;
    }
  }

  & + .item-list,
  & + .item-area {
    margin-top: 9px;
  }

  .item-list + & {
    margin-top: 27px;
  }
}

.heading-content {

  h2, h3, h4 {
    font-weight: 600;
  }

  & + p {
    margin-top: 0.5em;
  }
}

h2 {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.25px;
}

h3 {
  font-weight: 200;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.25px;
}

h4 {
  font-weight: 200;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.5px;
}

h5 {
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
}
