/* FIELDS
\___________________________________________*/

fieldset {
  h3 {
    margin: 18px 0;
    font-size: 12px;
  }
}

@import 'field';
@import 'autocomplete-list';

@import 'button-new';
@import 'buttons-new';

@import 'record-delete';
