.item-actions {
  display: flex;
  align-items: center;

  .item-action {
    transition: margin-left 0.4s ease,
                opacity 0.4s ease,
                transform 0.4s ease;

    .item-action-el {
      cursor: pointer;
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      transition: background-color 0.4s ease,
                  opacity 0.4s ease;

      &:hover {
        opacity: 1;
      }

      &[disabled],
      &[disabled="disabled"],
      &[data-disabled="true"] {
        cursor: default;
        opacity: 0.1;
      }
    }

    .item-action-has-icon {
      padding-left: 100%;
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 50%;
      overflow: hidden;
    }

    .item-action-has-graphic {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      display: block;
      width: 12px;
      height: 12px;
    }

    .item-action-button {
      background-color: transparent;

      &.item-action-omit-text {
        text-indent: 150%;
      }
    }

    &.item-hide {
      opacity: 0;
      transform: translateX(24px);
      visibility: hidden;
    }

    &.item-action-menu {
      position: relative;

      .item-action-el {
        &.item-action-ellipses {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            background-image: image( 'icons/ui/waiting-black.svg' );
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 45%;
            width: 27px;
            height: 27px;
          }

          .item-action-label {
            display: none;
          }
        }
      }

      &.item-active {
        &::before {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          content: '';
          display: block;
          z-index: 4;
        }
      }

      .item-menu {
        position: absolute;
        top: 18px;
        right: 0;
        z-index: 5;
        transition: opacity 0.2s ease,
                    transform 0.2s ease,
                    visibility 0.2s ease;

        &.active {
          opacity: 1;
          transform: translateY(0) scale(1);
          visibility: visible;
        }

        &.inactive {
          opacity: 0;
          transform: translateY(-6px) scale(0.9);
          visibility: hidden;
        }
      }

      .item-menu-option {
        .item-action {
          padding-left: 0;
          width: 100%;
          height: auto;
        }

        .item-action-el {
          display: flex;
          align-items: center;
          padding: 0 9px;
          height: 24px;
          background-position: 6px 6px;
          background-size: 12px 12px;
          border-radius: 12px;
          color: inherit;
          font-weight: bold;
          font-size: 12px;
          text-align: left;
          white-space: nowrap;

          &.item-action-has-icon {
            padding-left: 27px;
          }
        }
      }
    }

    &.item-action-add {
      .item-action-el.item-action-has-icon {
        background-image: image( 'icons/ui/plus-thin-black.svg' );
      }
    }

    &.item-action-edit {
      .item-action-el.item-action-has-icon {
        background-image: image( 'icons/ui/edit-black.svg' );
      }
    }

    &.item-action-done {
      .item-action-el.item-action-has-icon {
        background-image: image( 'icons/ui/check-black.svg' );
      }
    }

    &.item-action-cite {
      .item-action-el.item-action-has-icon {
        background-image: image( 'icons/ui/citation-black.svg' );
      }
    }

    &.item-action-save {
      .item-action-el {
        opacity: 1;
        background-color: rgba( var( --color-blue-medium-rgb ), 0.65 );
        color: var(--color-white);

        &.item-action-has-icon {
          background-image: image( 'icons/ui/check-white.svg' );
        }

        &:hover {
          background-color: rgba( var( --color-blue-medium-rgb ), 1 );
        }

        &[disabled] {
          background-color: rgba( var(--color-black-rgb), 0.2 );

          &:hover {
            background-color: rgba( var(--color-black-rgb), 0.2 );
          }
        }

        &[data-action-required="true"] {
          animation: 0.8s ease 0.4s infinite alternate pulse-save;
        }
      }

      &.action-required {
        .item-action-el {
          animation: 0.8s ease 0.4s infinite alternate pulse-save;
          background-color: rgba( var( --color-blue-medium-rgb ), 0.65 );

          &:hover {
            background-color: rgba( var( --color-blue-medium-rgb ), 1 );
          }
        }
      }

      &.success {
        .item-action-el {
          background-image: image( 'icons/ui/check-green.svg' );
        }
      }
    }

    &.item-action-tag {
      .item-action-el.item-action-has-icon {
        background-image: image( 'icons/ui/tag-black.svg' );
      }
    }

    &.item-action-cancel {
      .item-action-el.item-action-has-icon {
        background-image: image( 'icons/ui/x-black.svg' );
      }
    }

    &.item-action-delete {
      .item-action-el.item-action-has-icon {
        background-image: image( 'icons/ui/delete-black.svg' );
      }
    }

    & + .item-action {
      margin-left: 4px;
    }
  }

  &.item-actions-small {
    .item-action {
      display: block;
      width: auto;
      min-width: 27px;
      height: 27px;
      border-radius: 50%;

      &:not(.item-action-menu) {
        overflow: hidden;
      }
    }

    .item-action-el {
      padding: 7.5px;
    }
  }

  &.item-actions-large,
  &.item-large-actions {
    .item-action {
      display: block;
      width: auto;
      min-width: 27px;
      height: 27px;

      & + .item-action {
        margin-left: 6px;
      }

      & + .item-action.item-hide {
        margin-left: -27px;
      }

      &.item-action-save {
        width: auto;
        min-width: 36px;
        height: 36px;
        border-radius: 36px;

        svg {
          margin: 0;
          width: 18px;
          height: 18px;
        }
      }

      .item-action-el {
        padding: 9px;
      }

      .item-action-button {
        border-radius: 18px;
        overflow: hidden;
      }
    }
  }
}
