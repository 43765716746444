.item-loading {
  $ui-height: 24px;

  opacity: 0.5;
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;

  &.has-icon {
    display: flex;

    &::before {
      content: '';
      display: block;
      margin-right: 4.5px;
      width: $ui-height;
      height: $ui-height;
      background-color: transparent;
      background-image: image( 'icons/ui/waiting-black.svg' );
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 40%;
    }
  }

  &.item-loading-large {
    font-size: 24px;
    line-height: 27px;

    &.has-icon {
      &::before {
        width: 27px;
        height: 27px;
      }
    }
  }

  &.item-loading-small {
    font-size: 13px;
    line-height: 18px;

    &.has-icon {
      &::before {
        width: 18px;
        height: 18px;
      }
    }
  }
}
