.item-data {
  cursor: default;
  display: inline-block;

  &.inactive {
    display: none;
  }

  .label {
    color: var( --color-gray-light );
    font-size: 8px;
    letter-spacing: 3px;
    text-transform: uppercase;

    & + .value {
      margin-left: 3px;
    }

    &.has-error {
      color: var( --color-red-medium );
    }
  }

  .value {
    color: var( --color-gray-medium );
    font-weight: 200;
    font-size: 9px;
    letter-spacing: 0.5px;
  }

  a.value {
    &:hover {
      color: var( --color-gray-darker );
      border-bottom: 1px solid var( --color-gray-darker );
    }
  }

  input[type="text"].value {
    padding: 0 3px;
    background-color: var( --color-gray-lightest );

    &::-webkit-input-placeholder {  
      color: var( --color-gray-medium );
    }

    &::-moz-placeholder {  
      color: var( --color-gray-medium );
    }

    &:-ms-input-placeholder {  
      color: var( --color-gray-medium );
    }
  }

  @include at_print {
    .label, .value {
      color: var(--color-black);
    }
  }
}

.item-data.item-date {
  .label {
    max-width: 0;
    opacity: 0;
    transition: all 0.4s ease;
  }

  .label + .value {
    margin-left: 0;
  }

  &:hover {
    .label {
      max-width: 60px;
      opacity: 1;
    }

    .label + .value {
      margin-left: 3px;
    }
  }
}

.item-data + .item-actions {
  margin-left: 8px;

  @include at_print {
    display: none;
  }
}
