.item-prompt-ui {
  --icon-unit: calc( 9px );
  --icon-size: calc( 6 * var( --icon-unit ) );
  --vertical-padding: calc( var( --icon-unit ) * 1.667 );

  @media screen and (max-width: $width-standard) {
    --icon-size: calc( 5 * var( --icon-unit ) );
    --vertical-padding: calc( var( --icon-unit ) * 1.333 );
  }

  position: relative;
  padding: var( --vertical-padding ) var( --icon-size ) var( --vertical-padding ) var( --icon-size );
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  transition: opacity 0.4s ease,
              padding-left 0.4s ease,
              padding-right 0.4s ease;

  &.has-icon {
    padding-left: var( --icon-size );

    &:before {
      width: var( --icon-size );
      height: var( --icon-size );
    }

    &.is-error {
      padding-left: calc( var( --icon-size ) + var( --icon-unit ) );
    }
  }

  &.active {
    opacity: 1;
  }

  &.inactive {
    padding: 0;
    height: 0;
    opacity: 0;
    // overflow: hidden;
  }

  &.has-icon {
    &:before {
      content: '';
      position: absolute;
      // top: calc( var( --icon-unit ) / 2 );
      top: 0;
      left: 0;
      display: block;
      background-color: transparent;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 50%;
    }

    &.is-question,
    &.is-results {
      background-color: var( --color-white );

      &:before {
        background-image: image( 'icons/octicons/chevron-right.svg' );
        background-size: 25%;
      }
    }

    &.is-waiting {
      background-color: rgba( var( --color-white-rgb ), 0 );
      opacity: 0.5;

      &:before {
        background-image: image( 'icons/ui/waiting-black.svg' );
        background-size: 40%;
      }
    }

    &.is-error {
      background-color: var(--color-white);

      &:before {
        background-image: image( 'icons/ui/warning-white.svg' );
        background-color: var( --color-red-medium );
      }
    }

    &.is-success {
      background-color: rgba( var( --color-white-rgb ), 0 );

      &:before {
        background-image: image( 'icons/ui/check-green.svg' );
      }
    }
  }

  &.is-directive {
    background-color: rgba( var( --color-white-rgb ), 0 );
  }

  .action {
    cursor: pointer;
    color: var( --color-link );

    &:hover {
      text-decoration: underline;
    }
  }
}
