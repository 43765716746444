td.field {
  border-bottom: none;
}

.field {
  // everything here is transitional
  // and will be generalized
  @extend %clearfix;
  border-bottom: 1px solid var( --color-gray-lighter );

  &.half {
    width: 48.25%;
  }

  &.quarter {
    width: 22.375%;
  }

  &.eighth {
    width: 7.6875%;
  }

  &.triggers {
    padding-bottom: 0;
    border-bottom: none;
  }

  label {
    display: block;
    margin-bottom: 0;
    padding: 12px 6px 0 0;
    color: var( --color-gray-medium );
    font-size: 11px;
    line-height: 14px;
    text-align: left;
    letter-spacing: 0.5px;

    &[for] {
      cursor: pointer;
    }

    & + p {
      margin: 6px 0 4px;
    }

    & + p.empty {
      margin: 0;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea,
  select,
  p,
  table {
    float: right;
    width: 100%;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea,
  select,
  p.editable {
    display: block;
    background-color: rgba( var( --color-white-rgb ), 0);
    font-size: 12px;
  }

  p {
    margin: 0;
    padding: 6px 0;
    color: var( --color-gray-medium );

    &.empty {
      padding-top: 12px;
    }
  }

  p.editable,
  table td {
    line-height: 18px;
  }

  table {
    display: table;
    table-layout: fixed;

    thead {
      display: none;
    }

    tr {

      td {
        background-color: rgba( var( --color-white-rgb ), 0 );
        transition: background-color 0.25s ease;

        &.title {
          width: 51.75%;
        }
      }
    }
  }

  textarea {
    height: 100%;
  }

  textarea.content_quote {
    background-color: rgba( var( --color-blue-lighter-rgb ), 0.25);

    &:hover {
      background-color: rgba( var( --color-blue-lighter-rgb ), 0.5);
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    margin-bottom: -2px;
    padding: 11px 0 10px;
    border-bottom: 2px solid transparent;
    line-height: 14px;
    transition: border 0.25s;

    &:hover,
    &:focus {
      border-bottom-color: var( --color-gray-medium );
    }
  }

  select {
    display: block;
    margin: 10px 0 7px 0;
    padding: 0;
    border-radius: 0;
    line-height: 24px;
  }

  input[type="checkbox"] {

    label + & {
      margin-left: 6px;
    }

    & + label {
      width: 16.5%;
    }
  }

  &.key {
    
    input[type="text"],
    input[type="password"] {
      padding-top: 8px;
      padding-bottom: 7px;
      font-size: 18px;
    }

    &.inline {

      input[type="text"],
      input[type="password"] {
        padding-top: 11px;
        padding-bottom: 10px;
      }
    }
  }

  &.inline {
    @extend %clearfix;

    label {
      position: absolute;
      padding-right: 0;
      width: 72px;
      border-bottom: none;
    }

    input[type="text"],
    input[type="password"],
    textarea,
    p {
      margin-left: 0;
      width: 75%;
    }

    table {
      margin-left: 2.25%;
      width: 76.25%;
    }

    select {
      margin-left: 2.25%;
      width: 76.25%;
    }

    input[type="text"],
    input[type="password"] {
      
      & + p {
        border-top: 1px solid var( --color-gray-lighter );
      }
    }

    &.boolean {

      input[type="checkbox"] {
        margin-left: 25%;

        & + label {
          margin-left: 26%;
          width: auto;
        }
      }
    }

    &.half,
    &.quarter {

      label {
        position: absolute;
        float: none;
        padding-right: 0;
        width: 72px;
      }

      input[type="text"],
      textarea,
      p,
      table {
        padding-left: 0;
        padding-right: 0;
        text-align: right;
      }

      select {
        margin-left: 2.25%;
        width: 76.25%;
      }
    }

    &.half {

      input[type="text"],
      textarea,
      p,
      table {
        width: 240px;
      }
    }

    &.quarter {

      input[type="text"],
      textarea,
      p,
      table {
        width: 72px;
      }
    }

    &.key {

      label {
        margin-top: 6px;
      }
    }

    &.boolean {

      label:first-child {
        width: 21.5%;
      }
    }

    &.triggers {
      margin-top: 9px;

      p {
        float: none;
        margin-left: 24.25%;
        width: 75.75%;
      }
    }

    p.helper {
      width: 75%;
      font-size: 10px;
      line-height: 15px;

      &:before {
        content: 'i';
        float: left;
        margin-right: 4px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: var( --color-gray-lighter );
        color: var(--color-white);
        text-align: center;
      }
    }
  }

  &.grouping {
    @extend %clearfix;

    border-bottom: none;

    .field {
      clear: none;
      float: left;
      padding-bottom: 0;

      & + .field {
        margin-left: 3.5%;
      }
    }

    .key ~ .field {

      &:not(.key) {

        input[type="text"] {
          padding-top: 10px;
          padding-bottom: 9px;
        }
      }
    }
  }

  &.boolean {
    padding-bottom: 9px;

    input[type="checkbox"] {
      float: left;
      margin-top: 13px;
      margin-left: 6px;

      & + label {
        float: left;
        margin-top: 7px;
        margin-left: 6px;
        padding-top: 4px;
        width: 74.75%;
        font-size: 12px;
        letter-spacing: 0;
        transition: color 0.25s ease;

        &:hover {
          color: var(--color-black);
        }
      }

      &:checked + label {
        color: var(--color-black);
      }
    }
  }

  .cta {
    color: var( --color-gray-light );
    transition: color 0.25s ease;

    &:hover {
      color: var( --color-gray-medium );
    }

    &.add {
      cursor: pointer;
      display: block;
      margin-left: 648px - 495px; // this needs to go in attributions-item
      width: 495px; // this needs to go in attributions-item
      height: 18px;
      font-size: 12px;
      line-height: 18px;

      &:before {
        content: ' ';
        float: left;
        display: block;
        margin-top: 1px;
        margin-right: 6px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: rgba(102, 153, 204, 0.4);
        background-image: image( 'icons/ui/plus-white.svg' );
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 50% 50%;
      }

      &:hover:before {
        color: var( --color-link );
      }
    }
  }
}

@media screen and (max-width: $width-standard) {

  .field {
    
    &.boolean {

      input[type="checkbox"] {

        & + label {
          float: right;
          margin-top: 11px;
          padding: 0;
          width: 75%;
        }
      }
    }

    &.inline {

      & + .inline {
        margin-top: 12px;
      }

      label {
        float: none;
        padding: 0;
        width: 100%;

        & + input[type="text"],
        & + textarea,
        & + p {
          margin: 0;
          width: 100%;
        }

        & + table {
          margin-top: 6px;
          width: 100%;
        }

        & + select {
          margin-top: 11px;
          margin-left: 0;
          width: 100%;
        }
      }

      &.half,
      &.quarter,
      &.eighth {

        label {
          position: relative;

          & + input[type="text"],
          & + textarea,
          & + p,
          & + table {
            padding-right: 0;
            text-align: left;
          }

          & + select {
            margin-left: 0;
            width: 100%;
          }
        }
      }

      &.key {

        label {
          margin-top: 0;
        }
      }

      &.triggers {
        margin-top: 9px;

        p {
          margin: 0;
          width: 100%;
          text-align: right;
        }
      }

      p.helper {
        width: 100%;
      }
    }

    &.half {
      width: 100%;
    }

    &.quarter {
      width: 100%;
    }

    &.eighth {
      width: 100%;
    }

    &.grouping {

      & + .grouping {
        margin-top: 12px;
      }

      .field {
        float: none;

        & + .field {
          margin-left: 0;
        }
      }
    }
  }
}
