.item-panel {
  padding: 21px;
  background-color: var( --color-white );
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba( var( --color-gray-medium-rgb ), 0.25 );
}

.item-panel-modal {
  padding: 21px;
  background-color: var( --color-white );
  border-radius: 9px;
  box-shadow: 0 2px 12px rgba( var( --color-gray-medium-rgb ), 0.5 );
}
