.button-new {
  position: relative;
  cursor: pointer;
  text-align: center;

  &.button-self,
  a {
    display: block;
    height: 100%;
    transition: color 0.25s ease, 
                background-color 0.25s ease;
  }

  // sizes

  .buttons-small &,
  &.button-small {
    width: $button-small-width;
    height: $button-small-height;
    font-weight: bold;
    font-size: 8px;
    letter-spacing: 3px;
    text-transform: uppercase;

    &.button-self,
    a {
      padding: 6px (8px - 3px) 4px 8px;
    }
  }

  .buttons-medium &,
  &.button-medium {
    width: $button-medium-width;
    height: $button-medium-height;
    font-weight: bold;
    font-size: 9px;
    letter-spacing: 3px;
    text-transform: uppercase;

    &.button-self,
    a {
      padding: 11px (8px - 3px) 9px 8px;
    }
  }

  .buttons-large &,
  &.button-large {
    width: $button-large-width;
    height: $button-large-height;
    font-weight: bold;
    font-size: 14px;
    line-height: $button-large-height;
    letter-spacing: 1px;

    &.button-self,
    a {
      padding: 11px (8px - 3px) 9px 8px;
    }
  }

  // pinned

  &.button-pinned {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
  }

  // types, colors

  &.button-cancel {

    &.button-self,
    a {
      color: var( --color-gray-medium );

      &:hover {
        color: var(--color-black);
      }
    }
  }

  &.button-edit {

    &.button-self,
    a {
      background-color: rgba( var( --color-blue-lighter-rgb ), 0.25);
      color: var( --color-blue-lighter );

      &:hover {
        background-color: rgba( var( --color-blue-lighter-rgb ), 0.5);
        color: var( --color-blue-lighter );
      }
    }

    &.button-small {

      &.button-self,
      a {
        padding-right: 4 + 18px;
        color: var(--color-white);

        &:before {
          content: ' ';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 18px;
          height: 18px;
          background-image: image( 'icons/ui/edit-white.svg' );
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: 12px;
          line-height: $button-mini-height - 2px;
        }
      }
    }
  }

  &.button-save {

    &.button-self,
    a {
      background-color: rgba( var( --color-blue-medium-rgb ), 0.75);
      color: var(--color-white);

      &:hover {
        background-color: rgba( var( --color-blue-medium-rgb ), 1);
        color: var(--color-white);
      }
    }

    &[data-action-required="true"] {
      animation: 0.8s ease 0.4s infinite alternate pulse-save;
    }
  }

  &.button-delete {

    &.button-self,
    a {
      background-color: rgba( var( --color-red-medium-rgb ), 0.75);
      color: var(--color-white);

      &:hover {
        background-color: rgba( var( --color-red-medium-rgb ), 1);
        color: var(--color-white);
      }
    }
  }

  &.button-delete-alt {

    &.button-self,
    a {
      background-color: rgba( var( --color-gray-lightest-rgb ), 0.75);
      color: var(--color-white);

      &:hover {
        background-color: rgba( var( --color-red-medium-rgb ), 1);
        color: var(--color-white);
      }
    }
  }

  &.button-remove-alt {

    &.button-self,
    a {
      background-color: rgba( var( --color-gray-lightest-rgb ), 0.75);
      color: var(--color-white);

      &:hover {
        background-color: rgba( var( --color-red-medium-rgb ), 1);
        color: var(--color-white);
      }
    }
  }

  &.button-self[disabled="disabled"],
  &[disabled="disabled"] a {
    cursor: default;
    background-color: var( --color-gray-lighter );

    &:hover {
      background-color: var( --color-gray-lighter );
    }
  }

  @include at_print {
    display: none;
  }
}
