.edit-item-trigger {
  --increment: 9px;

  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: transparent;
  opacity: 0.4;
  transition: border 0.4s ease,
              opacity 0.4s ease;

  .add-item-trigger-label {
    display: none;
    margin-left: var( --increment );
    color: inherit;
    font-weight: 200;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.5px;
    transition: color 0.4s ease;
  }

  &:hover {
    border-color: var( --color-gray-medium );
    opacity: 1;
  }
}
