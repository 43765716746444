.item-color-options {
  display: flex;
  min-height: 28px;

  @media screen and (min-width: $width-mobile) {
    .item-color-option + .item-color-option {
      margin-left: 9px;
    }
  }

  @media screen and (max-width: $width-mobile) {
    flex-direction: column;

    .item-color-option + .item-color-option {
      margin-top: 9px;
    }
  }
}

.item-color-option {
  position: relative;

  &.item-color-option-red {
    .item-color-option-label {
      &::before {
        background-color: var( --item-color-red );
      }
    }
  }

  &.item-color-option-orange {
    .item-color-option-label {
      &::before {
        background-color: var( --item-color-orange );
      }
    }
  }

  &.item-color-option-yellow {
    .item-color-option-label {
      &::before {
        background-color: var( --item-color-yellow );
      }
    }
  }

  &.item-color-option-green {
    .item-color-option-label {
      &::before {
        background-color: var( --item-color-green );
      }
    }
  }

  &.item-color-option-blue {
    .item-color-option-label {
      &::before {
        background-color: var( --item-color-blue );
      }
    }
  }

  &.item-color-option-purple {
    .item-color-option-label {
      &::before {
        background-color: var( --item-color-purple );
      }
    }
  }

  &.item-color-option-brown {
    .item-color-option-label {
      &::before {
        background-color: var( --item-color-brown );
      }
    }
  }

  &.item-color-option-black {
    .item-color-option-label {
      &::before {
        background-color: var( --item-color-black );
      }
    }
  }

  &.selected {
    .item-color-option-label {
      &::before {
        border-color: var(--color-black);
      }

      span {
        color: var(--color-black);
      }
    }
  }

  @media screen and (max-width: $width-mobile) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.selected {
      &::after {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        background-image: image( 'icons/ui/check-black.svg' );
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;      
      }
    }
  }
}

.item-color-option-label {
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid var( --color-gray-lighter );
    transition: border-color 0.4s ease;
  }

  input[type="radio"] {
    position: absolute;
    visibility: hidden;
  }

  span {
    transition: color 0.4s ease;
  }

  &:hover {
    &::before {
      border-color: var(--color-black);
    }

    span {
      color: var(--color-black);
    }
  }

  @media screen and (min-width: $width-mobile) {
    span {
      display: none;
    }
  }

  @media screen and (max-width: $width-mobile) {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      margin-left: 9px;
      color: var( --color-gray-medium );
      font-size: 14px;
    }
  }
}
