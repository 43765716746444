$layout-width-margin:  36px;
$layout-width-wide:   342px;
$layout-width-medium: 243px;
$layout-width-small:  144px;
$layout-height-margin: 27px;

$button-mini-width: 27px;
$button-mini-height: 27px;

$button-small-width: 56px;
$button-small-height: 18px;

$button-medium-width: 84px;
$button-medium-height: 30px;

$button-large-width: 18px * 18;
$button-large-height: 36px;
