.global-header {
  $header-height: 36px;

  .global-nav-title {
    width: 162px - $header-height;
    background-color: var( --color-blue-dusty-medium );
    font-weight: 600;
    font-size: 9px;
    letter-spacing: 4px;

    a {
      display: block;
      color: var(--color-white);
      line-height: $header-height - 6px;
      text-align: center;
      text-transform: uppercase;
    }
  }
}
