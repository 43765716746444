:root {
  --color-white:                    #fff;
  --color-white-rgb:                255, 255, 255;
  --color-black:                    #000;
  --color-black-rgb:                0, 0, 0;

  --color-gray-darker:              #333;
  --color-gray-darker-rgb:          51, 51, 51;

  --color-gray-medium:              #808080;
  --color-gray-medium-rgb:          128, 128, 128;
  --color-gray-light:               #a6a6a6;
  --color-gray-lighter:             #ccc;
  --color-gray-lighter-rgb:         204, 204, 204;
  --color-gray-lightest:            #e6e6e6;
  --color-gray-lightest-rgb:        230, 230, 230;

  --color-blue-medium:              #15b;
  --color-blue-medium-rgb:          17, 85, 187;
  --color-blue-lighter:             #48e;
  --color-blue-lighter-rgb:         68, 136, 238;

  --color-blue-dusty-medium:        #58a;
  --color-blue-dusty-medium-rgb:    85, 136, 170;
  --color-blue-dusty-lighter-rgb:   159, 192, 202;
  --color-blue-dusty-lightest:      #e1ebee;
  --color-blue-dusty-lightest-rgb:  225, 235, 238;

  --color-red-medium:               #b73737;
  --color-red-medium-rgb:           183, 55, 55;

  --color-burlywood-rgb:            222, 184, 135;
  --color-darksandybrown-rgb:       244, 164, 96;

  --color-darkseagreen-rgb:         143, 188, 143;

  --color-green-medium:             #8a5;
  --color-green-medium-rgb:         136, 170, 85;

  --color-background-rgb:           242, 241, 237;
  --color-background:               rgb( var( --color-background-rgb ) );
  --color-default:                  var( --color-gray-darker );

  --color-link:                     var( --color-blue-dusty-medium );
  --color-link-rgb:                 var( --color-blue-dusty-medium-rgb );
  --color-link-hover:               var( --color-black );

  --item-color-red: tomato;
  --item-color-orange: sandybrown;
  --item-color-yellow: goldenrod;
  --item-color-green: yellowgreen;
  --item-color-blue: dodgerblue;
  --item-color-purple: rebeccapurple;
  --item-color-brown: saddlebrown;
  --item-color-black: black;
  --item-color-white: var( --color-white );
}
