.add-item-triggers {
  display: flex;
  transition: opacity 0.25s ease;

  &.active {
    opacity: 1;

    .add-item-trigger {
      cursor: pointer;
    }
  }

  &.inactive {
    opacity: 0.2;

    .add-item-trigger {
      cursor: default;
    }
  }
}

.add-item-trigger {
  --increment: 9px;

  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid var( --color-gray-medium );
  border-radius: 50%;
  background-color: transparent;
  opacity: 0.4;
  transition: opacity 0.4s ease;

  &::before {
    content: '';
    display: block;
    flex-shrink: 0;
    width: var( --increment );
    height: var( --increment );
    background-color: transparent;
    background-image: image( 'icons/ui/plus-thin-black.svg' );
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .add-item-trigger-label {
    display: none;
    margin-left: var( --increment );
    color: inherit;
    font-weight: 200;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.5px;
    transition: color 0.4s ease;
  }

  &:hover {
    opacity: 1;
  }
}
