.tooltip {
  $tooltip-background: rgba( var( --color-gray-darker-rgb ), 0.9);

  position: absolute;
  top: 24px;
  left: calc(-100% - 1px);
  width: 180px;
  z-index: 3;

  &::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 50%;
    width: 0;
    border: 6px solid transparent;
    border-top: 0;
    border-bottom: 6px solid $tooltip-background;
    transform: translate(-50%, calc(-100% - 5px));
  }

  .tooltip-content {
    padding: 9px 12px;
    background-color: $tooltip-background;
    border-radius: 6px;
    color: var(--color-white);
    font-weight: 200;
    font-size: 11px;
    line-height: 15px;
  }

  &.active {
    display: block;
  }

  &.inactive {
    display: none;
  }
}

.tooltip-right {
  left: auto;
  right: -12px;

  &::before {
    left: auto;
    right: 9px;
  }
}
