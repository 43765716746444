@keyframes cameraflash {
  0%    { background-color: inherit; }
  20%   { background-color: rgba( var( --color-white-rgb ), 0.5 ); }
  100%  { background-color: inherit; }
}

@keyframes pulse-save {
  from {
    background-color: rgba( var( --color-blue-medium-rgb ), 0.65 );
  }
  to {
    background-color: rgba( var( --color-blue-medium-rgb ), 1 );
    box-shadow: 0 0 4px var( --color-blue-lighter );
  }
}
